export const revenueReportRoutes = [
    {
        path: "/report",
        name: "report",
        meta: {
            title: "营收报表",
            key: 'report',
            pageApisPrm: import("@/views/revenueReport/revenueReport/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/revenueReport/revenueReport/Index.vue"),
    },
]