import store from "@/store";
const is_zxbilliard:boolean = location.host.indexOf('zxbilliard')>-1
store.commit('setPlatformType',is_zxbilliard ? 2 : 1)
const platformType:number = store.state.platformType
var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
// link.type = 'image/png';
// link.rel = 'icon';
let panda_ico_url:string = 'https://cashier.pandaball.cc/images/panda.ico'
let zx_ico_url:string = 'https://cashier.pandaball.cc/images/logo.ico'
link.href = platformType===2 ? zx_ico_url: panda_ico_url;
document.getElementsByTagName('head')[0].appendChild(link);
document.title = platformType===2 ? '众享计费系统' : '熊猫收银'
