export const couponsRoutes = [
    {
        path: "/coupons",
        name: "coupons",
        meta: {
            title: "优惠券设置",
            key: 'coupons-set',
            pageApisPrm: import("@/views/couponManage/couponSet/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/couponManage/couponSet/Index.vue"),
    },
    {
        path: "/givecoupons",
        name: "givecoupons",
        meta: {
            title: "赠送优惠券",
            key: 'give-coupons',
            pageApisPrm: import("@/views/couponManage/giveCoupon/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/couponManage/giveCoupon/Index.vue"),
    },
]