export const goodsManageRoutes = [
    {
        path: "/goodsell",
        name: "goodsell",
        meta: {
            title: "商品售卖",
            key: 'good-sell',
            pageApisPrm: import("@/views/goodsManage/goodsSell/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/goodsManage/goodsSell/Index.vue"),
    },
    {
        path: "/goodsList",
        name: "goodsList",
        meta: {
            title: "商品列表",
            key: 'goods-list',
            pageApisPrm: import("@/views/goodsManage/goodsList/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () =>
            import("@/views/goodsManage/goodsList/Index.vue"),
    },
    {
        path: "/goodsClass",
        name: "goodsClass",
        meta: {
            title: "商品分类",
            key: 'goods-class',
            pageApisPrm: import("@/views/goodsManage/goodsClass/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () =>
            import("@/views/goodsManage/goodsClass/Index.vue"),
    },
]