export const coachManageRoutes = [
    {
        path: "/coachlist",
        name: "coachlist",
        meta: {
            title: "助教列表",
            key: 'coach-list',
            pageApisPrm: import("@/views/coachManage/coachList/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/coachManage/coachList/Index.vue"),
    },
]