export const dataStatisticsRoutes = [
    {
        path: "/dataStatistics",
        name: "dataStatistics",
        meta: {
            title: "数据统计",
            key: 'data-statistics',
            pageApisPrm: import("@/views/dataStatistics/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/dataStatistics/Index.vue"),
    }
]