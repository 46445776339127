export const accountManageRoutes = [
    {
        path: "/userManagement",
        name: "userManagement",
        meta: {
            title: "账号管理",
            key: 'user-management',
            pageApisPrm: import("@/views/accountManage/accountList/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/accountManage/accountList/Index.vue"),
    },
    {
        path: "/permissionSet",
        name: "permissionSet",
        meta: {
            title: "权限设置",
            key: 'permission-set',
            pageApisPrm: import("@/views/accountManage/permissionSet/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/accountManage/permissionSet/Index.vue"),
    }
]