import {BasicsClass} from "@/common/BasicsClass";

export default class PermissionSetAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/department/list',
            name: 'list',
            label: '部门列表'
        }],
        ['add', {
            url: '/department/add',
            name: 'add',
            label: '添加'
        }],
        ['edit', {
            url: '/department/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['delete', {
            url: '/department/delete',
            name: 'del',
            label: '删除'
        }],
        ['manageMenu', {
            url: '/permissions/manageMenu',
            name: 'manageMenu',
            label: '权限菜单管理'
        }],
        ['saveManageMenu', {
            url: '/permissions/save',
            name: 'saveManageMenu',
            label: '修改角色权限'
        }],
        [
            'getRightMenuTreeConfigList', {
            url: '/permissions/menus',
            name: 'get-menu-config-list',
            label: '左侧导航菜单'
        }],
        [
            'getRightMenuTreeBatchList', {
            url: '/cashier_menu/list',
            name: 'get-menu-batch-list',
            label: '获取批量配置菜单栏'
        }],
        ['batchAddMenuList', {
            url: '/cashier_menu/multi_add_edit',
            name: 'batch-add-menu',
            label: '批量添加或修改管理员菜单'
        }]
    ])

    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public addDepartment(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('add').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public editDepartment(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('edit').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }


    public deleteDepartment(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('delete').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }


    public manageMenu(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('manageMenu').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public editDepartRightmenus(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('saveManageMenu').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public getRightMenuTreeConfigList(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getRightMenuTreeConfigList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public getRightMenuTreeBatchList(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {

        const apiUrls = this.apiUrls

        const url = apiUrls.get('getRightMenuTreeBatchList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public batchAddMenuList(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('batchAddMenuList').url
        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
