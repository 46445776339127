import { Axios, AxiosData } from '@/common/http/http';
import { BasicsClass } from '@/common/BasicsClass';

interface Result {
    access_token: string;
    token_type: string;
    refresh_token: string;
    id: string;
    name: string;
    showMerchantsSelectionBox: boolean;
    groupCode: string;
}

export interface login {
    username: string;
    password: string;
    type: number
}

export interface LoginUserMerchants {
    permissionMchId: string;
    id: string;
    username: string;
    password: string;
    company: string;
    name: string;
    mobile: string;
    email: string;
    mchId: string;
    status: number;
    createTime: string;
    updateTime: string;
    stopTime: string;
    createUserId: string;
    updateUserId: string;
    isAdmin: boolean;
    deleteFlag: boolean;
    roles: string[];
    roleIds: string;
    roleNames: string;
}

export class Login extends BasicsClass {

    private axios: Axios;

    constructor() {
        super();
        this.axios = new Axios();
    }

    public async submitForm(param: login, callBack: Function, errCb: Function) {
        try {
            const loginTokenData: any = await this.axios.post<Result>('/account/login', {
                name: param.username,
                password: param.password,
                type: param.type
            });
            const {data} = loginTokenData || {}
            const {token: accessToken,} = data || {}
            accessToken && localStorage.setItem('token', accessToken)
            callBack(data);
        } catch (e: any) {

            if (e.message) {
                errCb(e.message);
            }

        }

    }
}
