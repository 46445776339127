 import store from "@/store";
let businessRoutes = [
    {
        path: "/business",
        name: "business",
        meta: {
            title: "营业",
            key: 'business',
            pageApisPrm: import("@/views/business/business/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/business/Index.vue"),
    },
    {
        path: "/members",
        name: "members",
        hidden:store.state.platformType===2,
        meta: {
            title: "会员管理",
            key: 'members',
            pageApisPrm: import("@/views/business/members/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/members/Index.vue"),
    },
    {
        path: "/membershis",
        name: "membershis",
        hidden:store.state.platformType===2,
        meta: {
            title: "会员开通记录",
            key: 'membershis',
            pageApisPrm: import("@/views/business/membershis/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/membershis/Index.vue"),
    },
    {
        path: "/orderlist",
        name: "orderlist",
        meta: {
            title: "订单记录",
            key: 'order-list',
            pageApisPrm: import("@/views/business/orderlist/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/orderlist/Index.vue"),
    },
    {
        path: "/groupBuying",
        name: "groupBuying",
        meta: {
            title: "线上团购",
            key: 'group-buying',
            pageApisPrm: import("@/views/business/groupBuying/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/groupBuying/Index.vue"),
    },
    {
        path: "/member_fee",
        name: "member_fee",
        meta: {
            title: "会员消费记录",
            key: 'member-fee',
            pageApisPrm: import("@/views/business/memberFee/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () =>
            import("@/views/business/memberFee/Index.vue"),
    },
    {
        path: "/card_fee",
        name: "card_fee",
        meta: {
            title: "单店卡消费记录",
            key: 'card-fee',
            pageApisPrm: import("@/views/business/cardFee/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/cardFee/Index.vue"),
    },
    {
        path: "/card_management",
        name: "card_management",
        meta: {
            title: "单店卡用户管理",
            key: 'card-management',
            pageApisPrm: import("@/views/business/cardManagement/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/cardManagement/Index.vue"),
    },
    {
        path: "/card_opening_history",
        name: "card_opening_history",
        meta: {
            title: "单店卡开通记录",
            key: 'card-opening-history',
            pageApisPrm: import("@/views/business/cardOpeningHistory/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/cardOpeningHistory/Index.vue"),
    },
    {
        path: "/singleStoreCardDeduction",
        name: "singleStoreCardDeduction",
        meta: {
            title: "单店卡扣款记录",
            key: 'single-storeCard-deduction',
            pageApisPrm: import("@/views/business/singleStoreCardDeduction/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/singleStoreCardDeduction/Index.vue"),
    },
    {
        path: "/singleStoreCardGive",
        name: "singleStoreCardGive",
        meta: {
            title: "单店卡赠送",
            key: 'single-store-card-give',
            pageApisPrm: import("@/views/business/singleStoreCardGive/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () =>
            import("@/views/business/singleStoreCardGive/Index.vue"),
    },
    {
        path: "/groupCardVerifyRecord",
        name: "groupCardVerifyRecord",
        meta: {
            title: "核销记录",
            key: 'group-card-verify-record',
            pageApisPrm: import("@/views/business/groupCardVerifyRecord/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () =>
            import("@/views/business/groupCardVerifyRecord/Index.vue"),
    },
]
 businessRoutes = businessRoutes.filter(item=>!item.hidden)
 console.log('营业路由',businessRoutes)
 export { businessRoutes }